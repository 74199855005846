import React from 'react';
import './Routes.css';

const Accounts = () => {
   return (
      <div className="Statistics">
         Счета
      </div>
   );
};

export default Accounts;